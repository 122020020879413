import Cookies from "js-cookie";

const TOKEN = "token";

const getToken = (): string | undefined => {
  const token = Cookies.get(TOKEN);
  return token;
};

const setToken = (token: string) => {
  Cookies.set(TOKEN, token, { expires: 1 });
};

const AdminStorage = { getToken, setToken };

export default AdminStorage;
