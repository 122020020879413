import { AREA } from "configs";
import AppHttpClient from "../../../core/http/index";

const getSubjectList = () =>
  AppHttpClient.post("/get_list_subject", { url: AREA.CHIBA });

const getExamList = () =>
  AppHttpClient.post("/get_list_exam", { url: AREA.CHIBA });

const newMarking = (body: FormData, options: any) =>
  AppHttpClient.postFormData("/marking", body, options);

const downloadCsvFile = (csv_name: string) =>
  AppHttpClient.post(
    `/download_csv?csv_name=${encodeURIComponent(csv_name)}`,
    {}
  );

const getExampleLink = (template_name: string) =>
  AppHttpClient.post(
    `/download_template?template_name=${encodeURIComponent(template_name)}`,
    {}
  );

const ExamService = {
  getExamList,
  getSubjectList,
  newMarking,
  downloadCsvFile,
  getExampleLink,
};
export default ExamService;
