import "./index.scss";
import AppButton from "components/AppButton";
import AppContent from "components/AppContent";
import DragDropFile from "components/AppDragDropFile";
import ToastMessage, { ToastPropsCustom } from "components/AppToastMessage";
import {
  ACCEPT_ANSWER,
  ACCEPT_ANSWER_DROP_ZONE,
  ACCEPT_SAMPLE_MARKSHEET,
  ACCEPT_SAMPLE_MARKSHEET_DROP_ZONE,
} from "configs";
import { useEffect, useRef, useState } from "react";
import { Button, Dropdown, Form, ProgressBar } from "react-bootstrap";
import AdminService from "./Api";
import AdminStorage from "./Storage";
import LoginModal from "./components/Login";
import AddPrefecture from "./components/AddPrefecture";
import { FaRegTrashAlt } from "react-icons/fa";
import ConfirmDeleteAreaModal from "./components/ConfirmDeleteAreaModal";
import { AxiosError } from "axios";
import { Link } from "react-router-dom";

type ResponseData = { name: string; value: string };

const AdminPageScreen = () => {
  const [showToastContent, setShowToastContent] = useState<
    Partial<ToastPropsCustom>
  >({
    message: null,
  });

  const [areaList, setAreaList] = useState<ResponseData[]>([]);
  const [subjectList, setSubjectList] = useState<ResponseData[]>([]);
  const [examList, setExamList] = useState<ResponseData[]>([]);
  const [uploadPercentage, setUploadPercentage] = useState<number>(0);
  const [isLoading, setLoading] = useState<boolean>(false);

  const [area, setArea] = useState<string>("");
  const [examName, setExamName] = useState<string>("");
  const [subjectName, setSubjectName] = useState<string>("");
  const [sampleMarkSheetFile, setSampleMarkSheetFile] = useState<File[]>([]);
  const [questionSheetFile, setQuestionSheetFile] = useState<File[]>([]);

  const [token, setToken] = useState<string | undefined>(
    AdminStorage.getToken()
  );

  const [openAddPrefecture, setOpenAddPrefecture] = useState<boolean>(false);

  const [selectedArea, setSelectedArea] = useState<ResponseData | null>(null);
  const [openConfirmDelete, setOpenConfirmDelete] = useState<boolean>(false);

  const timeOut = useRef<any>();

  const validateFiles = () => {
    if (
      sampleMarkSheetFile.some(
        (file: File) => !ACCEPT_SAMPLE_MARKSHEET.includes(file.type)
      )
    ) {
      setShowToastContent({
        message: "解答用紙のファイルはpdfのみ受け付けます。",
        type: "danger",
        title: "エラー",
      });
      return false;
    }

    if (
      questionSheetFile.some((file: File) => !ACCEPT_ANSWER.includes(file.type))
    ) {
      setShowToastContent({
        message: "解答用紙のファイルはxlsxのみ受け付けます。",
        type: "danger",
        title: "エラー",
      });
      return false;
    }
    return true;
  };

  const onDeleteArea = (area: ResponseData) => {
    setSelectedArea(area);
    setOpenConfirmDelete(true);
  };

  const closeConfirmDelete = () => {
    setSelectedArea(null);
    setOpenConfirmDelete(false);
  };

  const onDeleteAreaSuccess = () => {
    if (selectedArea?.value === area) {
      setArea(areaList[0].value);
    }
    closeConfirmDelete();
  };

  const handleSubmit = async () => {
    const validate = validateFiles();
    if (!validate) return;

    const formData = new FormData();
    sampleMarkSheetFile.forEach((file) => {
      formData.append("pdf_file", file);
    });
    formData.append("excel_file", questionSheetFile[0]);
    const areaForm =
      areaList.find((item) => item.value.toString() === area)?.name ?? "";
    formData.append("area", areaForm);
    formData.append("subject", subjectName);
    formData.append("exam", examName);

    const options = {
      onUploadProgress: (progressEvent: any) => {
        const { loaded, total } = progressEvent;
        let percent = Math.floor((loaded * 100) / total);
        if (percent < 95) {
          setUploadPercentage(percent);
        } else {
          setUploadPercentage(95);
        }
      },
    };

    setLoading(true);

    try {
      const response = await AdminService.uploadAnswer(formData, options);
      if (response.data) {
        setUploadPercentage(100);
        clearTimeout(timeOut.current);
        timeOut.current = setTimeout(() => {
          setLoading(false);
          setUploadPercentage(0);
          setShowToastContent({
            message: "各ファイルが提出されました",
            type: "success",
            title: "成功",
          });
        }, 1000);
      }
    } catch (error) {
      console.log(error);
      setUploadPercentage(0);
      setLoading(false);
      setShowToastContent({
        message: ((error as AxiosError).response?.data as any).detail || "",
        type: "danger",
        title: "エラー",
      });
    }
  };

  const refetchAreaList = async () => {
    try {
      const response = await AdminService.getAreaList();
      if (response.data.Areas) {
        const { Areas } = response.data;
        setAreaList(Areas);
      }
    } catch (error) {
      console.log(error);
    }
  };

  // get area list
  useEffect(() => {
    const getAreaList = async () => {
      try {
        const response = await AdminService.getAreaList();
        if (response.data.Areas) {
          const { Areas } = response.data;
          setAreaList(Areas);
          setArea(Areas[0].value);
        }
      } catch (error) {
        console.log(error);
      }
    };

    if (token) {
      getAreaList();
    }
  }, [token]);

  // get subject list
  useEffect(() => {
    const getSubjectList = async () => {
      try {
        const response = await AdminService.getSubjectList({ url: area });
        if (response.data.Subjects) {
          const { Subjects } = response.data;

          setSubjectList(Subjects);
          setSubjectName(Subjects[0].name);
        }
      } catch (error) {
        console.log(error);
      }
    };

    const handleGetExamList = async (area: string) => {
      try {
        const body = {
          url: area,
        };
        const response = await AdminService.getExamList(body);
        const { Exams } = response.data;
        if (Exams) {
          setExamList(Exams);
          setExamName(Exams[0].name);
        }
      } catch (error) {
        console.log(error);
      }
    };

    if (area) {
      getSubjectList();
      handleGetExamList(area);
    }
  }, [area]);

  // clear timeout
  useEffect(() => {
    return () => {
      if (timeOut.current) {
        clearTimeout(timeOut.current);
      }
    };
  }, []);

  return (
    <AppContent>
      <ToastMessage
        type={showToastContent.type}
        message={showToastContent.message || ""}
        isShow={!!showToastContent.message}
        onClose={() => setShowToastContent({ message: null })}
        title={showToastContent.title || ""}
      />
      {!token ? (
        <LoginModal
          setToken={setToken}
          setShowToastContent={setShowToastContent}
        />
      ) : (
        <div className="exam-container">
          <div className="exam-container__form">
            <div className="exam-container__form__title">県</div>
            <div className="exam-container__form__item position-relative">
              <Dropdown className="form-dropdown">
                <Dropdown.Toggle className="form-select">
                  {areaList.find((item) => item.value === area)?.name}
                </Dropdown.Toggle>
                <Dropdown.Menu>
                  {areaList.map((item, idx) => (
                    <Dropdown.Item
                      key={idx}
                      onClick={() => setArea(item.value)}
                      className={`${
                        area === item.value ? "active" : undefined
                      } d-flex justify-content-between align-items-center item-dropdown`}
                    >
                      <span className="text-truncate">{item.name}</span>
                      <Button
                        variant="outline-danger"
                        size="sm"
                        className="flex-shrink-0"
                        onClick={(e) => {
                          e.stopPropagation();
                          onDeleteArea(item);
                        }}
                      >
                        <FaRegTrashAlt />
                      </Button>
                    </Dropdown.Item>
                  ))}
                  <Dropdown.Divider />
                  <Dropdown.Item onClick={() => setOpenAddPrefecture(true)}>
                    追加
                  </Dropdown.Item>
                </Dropdown.Menu>
              </Dropdown>
              <Link
                to={`${window.location.origin}/${area}`}
                target="_blank"
                className="mt-1 d-block"
              >
                {window.location.origin}/{area}
              </Link>
            </div>
          </div>
          <div className="exam-container__form">
            <div className="exam-container__form__title">教科</div>
            <div className="exam-container__form__item">
              <Form.Select
                aria-label="Default select example"
                onChange={(e) => setSubjectName(e.target.value)}
              >
                {subjectList &&
                  subjectList.map((item, idx) => (
                    <option key={idx} value={item.value}>
                      {item.name}
                    </option>
                  ))}
              </Form.Select>
            </div>
          </div>
          <div className="exam-container__form">
            <div className="exam-container__form__title">試験選択</div>
            <div className="exam-container__form__item">
              <Form.Select
                aria-label="Default select example"
                className="form-select"
                onChange={(e) => setExamName(e.target.value)}
                value={examName}
              >
                {examList &&
                  examList.map((item, idx) => (
                    <option key={idx} value={item.value}>
                      {item.name}
                    </option>
                  ))}
              </Form.Select>
            </div>
          </div>

          <div className="exam-container__form">
            <div className="exam-container__form__title">
              マークシートのサンプル
            </div>
            <div className="exam-container__form__item">
              <DragDropFile
                textGuide={
                  <>
                    こちらにファイルをドラッグアンドドロップするか、
                    <br />
                    ファイルを選択するためにクリックしてください。
                  </>
                }
                isMultiple={true}
                setFileValues={(file) => setSampleMarkSheetFile(file)}
                uploadPercent={uploadPercentage}
                acceptFile={ACCEPT_SAMPLE_MARKSHEET_DROP_ZONE}
              />
            </div>
          </div>

          <div className="exam-container__form">
            <div className="exam-container__form__title">回答</div>
            <div className="exam-container__form__item">
              <DragDropFile
                textGuide={
                  <>
                    こちらにファイルをドラッグアンドドロップするか、
                    <br />
                    ファイルを選択するためにクリックしてください。
                  </>
                }
                isMultiple={false}
                setFileValues={(file) => setQuestionSheetFile(file)}
                uploadPercent={uploadPercentage}
                acceptFile={ACCEPT_ANSWER_DROP_ZONE}
              />
            </div>
          </div>

          {uploadPercentage !== 0 && (
            <div className="exam-container__form">
              <ProgressBar
                animated
                variant="success"
                now={uploadPercentage}
                label={`${uploadPercentage}%`}
              />
            </div>
          )}

          <div className="exam-container__form">
            <AppButton
              text="採点"
              btntype={"primary"}
              type="button"
              disabled={
                !sampleMarkSheetFile.length ||
                !questionSheetFile.length ||
                isLoading
              }
              onClick={() => handleSubmit()}
            />
          </div>
        </div>
      )}
      <AddPrefecture
        openAddPrefecture={openAddPrefecture}
        setOpenAddPrefecture={setOpenAddPrefecture}
        setShowToastContent={setShowToastContent}
        refetchAreaList={refetchAreaList}
      />
      <ConfirmDeleteAreaModal
        open={openConfirmDelete}
        name={selectedArea?.name || ""}
        onClose={closeConfirmDelete}
        setShowToastContent={setShowToastContent}
        refetchAreaList={refetchAreaList}
        onSuccess={onDeleteAreaSuccess}
      />
    </AppContent>
  );
};

export default AdminPageScreen;
