import React, { FC, ReactNode } from "react";
import "./index.scss";
import AppNavbar from "components/AppNavbar";
import { useLocation } from "react-router-dom";

interface Props {
  children: ReactNode;
}

const AppLayout: FC<Props> = ({ ...props }) => {
  const { children } = props;

  const location = useLocation();

  const isAdminPage = () => {
    return location.pathname.includes("/admin");
  };

  return (
    <div className="ui-layout">
      <div className="ui-layout__navbar">
        <AppNavbar />
      </div>
      <div
        className={`ui-layout__children ${
          isAdminPage() ? "ui-layout__admin" : ""
        }`}
      >
        {children}
      </div>
    </div>
  );
};

export default AppLayout;
