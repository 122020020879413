import React, { FC, useEffect, useState } from "react";
import "./index.scss";
import Image from "react-bootstrap/Image";
import Logo from "../../assets/logo.png";
import PDF_Icon from "../../assets/pdf-white-icon.png";
import EXCEL_Icon from "../../assets/excel-white-icon.png";
import { NAME_OF_LINK_SAMPLE } from "configs";
import ExamService from "features/Homepage/Api";
import { useLocation } from "react-router-dom";

const AppNavbar: FC = () => {
  const [fileLink, setFileLink] = useState<{ PDF: string }>(); //; EXCEL: string
  const location = useLocation();
  // console.log(location.pathname);

  const getLink = async () => {
    if (location.pathname.includes("/anjo")) {
      const res = await ExamService.getLastestFile(
        NAME_OF_LINK_SAMPLE.ANJO_PDF
      );
      return NAME_OF_LINK_SAMPLE.ANJO_PDF + "/" + res.data.name;
    }
    if (location.pathname.includes("/chiba")) {
      const res = await ExamService.getLastestFile(
        NAME_OF_LINK_SAMPLE.CHIBA_PDF
      );
      return NAME_OF_LINK_SAMPLE.CHIBA_PDF + "/" + res.data.name;
    }
    const res = await ExamService.getLastestFile(NAME_OF_LINK_SAMPLE.AICHI_PDF);
    return NAME_OF_LINK_SAMPLE.AICHI_PDF + "/" + res.data.name;
  };

  const isAdminPage = () => {
    return location.pathname === "/admin";
  };

  useEffect(() => {
    const getFileLink = async () => {
      const link = await getLink();
      const markSheetManual = Promise.resolve(ExamService.getExampleLink(link));
      // const markSheetExcel = Promise.resolve(
      //   ExamService.getExampleLink(NAME_OF_LINK_SAMPLE.EXCEL)
      // );
      Promise.all([markSheetManual]) //, markSheetExcel
        .then((values) => {
          const markSheetManualLink = values[0]?.data?.url;
          // const markSheetExcelLink = values[1]?.data?.url;
          setFileLink({
            PDF: markSheetManualLink,
            // EXCEL: markSheetExcelLink,
          });
        })
        .catch((err) => console.log(err));
    };
    !isAdminPage() && getFileLink();
  }, []);

  return (
    <div className={`ui-navbar ${isAdminPage() ? "ui-navbar-admin" : ""}`}>
      <div className="ui-navbar__logo">
        <a
          className="ui-navbar__logo__img"
          href="http://www.sing.co.jp/"
          target="_blank"
          rel="noreferrer"
        >
          <Image src={Logo} alt="logo" />
        </a>
        {isAdminPage() ? "マークシート管理画面" : "マークシート採点"}
      </div>

      {!isAdminPage() && (
        <div className="ui-navbar__link">
          <a
            className="ui-navbar__link__item"
            href={fileLink?.PDF}
            target="_blank"
            rel="noreferrer"
          >
            <Image src={PDF_Icon} alt="pdf" />
            <div>「マニュアル」PDF</div>
          </a>
          {/* <a
          className="ui-navbar__link__item"
          href={fileLink?.EXCEL}
          target="_blank"
          rel="noreferrer"
        >
          <Image src={EXCEL_Icon} alt="excel" />
          <div>「個票」Excel</div>
        </a> */}
        </div>
      )}
    </div>
  );
};

export default AppNavbar;
