import React from "react";
import { Form, Modal } from "react-bootstrap";
import AppButton from "components/AppButton";
import { ToastPropsCustom } from "components/AppToastMessage";
import AdminService from "features/Admin/Api";
import AdminStorage from "features/Admin/Storage";
import "./index.scss";

export default function LoginModal({
  setToken,
  setShowToastContent,
}: {
  setToken: (token: string) => void;
  setShowToastContent: (message: Partial<ToastPropsCustom>) => void;
}) {
  const [username, setUsername] = React.useState<string>("");
  const [password, setPassword] = React.useState<string>("");

  const [validated, setValidated] = React.useState<boolean>(false);

  const [loading, setLoading] = React.useState<boolean>(false);

  const [serverError, setServerError] = React.useState<string>("");

  const handleSubmit = async (e: React.FormEvent) => {
    e.preventDefault();
    setValidated(true);
    setServerError("");

    if (!username || !password) return;

    try {
      setLoading(true);
      const res = await AdminService.login({ username, password });
      const token = res.data.token;
      AdminStorage.setToken(token);
      setLoading(false);
      setToken(token);
      setShowToastContent({
        message: "ログインに成功しました。",
        type: "success",
        title: "成功",
      });
    } catch (error) {
      setLoading(false);
      setServerError("ユーザー名またはパスワードが間違っています。");
    }
  };

  const invalidUsername = validated && !username;
  const invalidPassword = validated && !password;

  return (
    <Modal
      show={true}
      backdrop="static"
      keyboard={false}
      className="login-modal"
    >
      <Modal.Header className="login-modal__header">
        <Modal.Title className="text-light">ログイン</Modal.Title>
      </Modal.Header>
      <Modal.Body className="login-modal__body">
        <Form onSubmit={handleSubmit}>
          {serverError && (
            <Form.Text className="text-danger login-modal__body--danger d-inline-block mb-2">
              {serverError}
            </Form.Text>
          )}
          <Form.Group className="mb-3" controlId="formBasicEmail">
            <Form.Label>ユーザー名</Form.Label>
            <Form.Control
              type="text"
              placeholder="ユーザー名を入力してください。"
              value={username}
              onChange={(e) => setUsername(e.target.value)}
              className={invalidUsername ? "border border-danger" : ""}
            />
            {invalidUsername && (
              <Form.Text className="text-danger login-modal__body--danger">
                ユーザー名を入力してください。
              </Form.Text>
            )}
          </Form.Group>
          <Form.Group className="mb-3" controlId="formBasicEmail">
            <Form.Label>パスワード</Form.Label>
            <Form.Control
              type="password"
              placeholder="パスワードを入力してください。"
              value={password}
              onChange={(e) => setPassword(e.target.value)}
              className={invalidPassword ? "border border-danger" : ""}
            />
            {invalidPassword && (
              <Form.Text className="text-danger login-modal__body--danger">
                パスワードを入力してください。
              </Form.Text>
            )}
          </Form.Group>
           
          <AppButton
            text="ログイン"
            btntype={"primary"}
            type="submit"
            disabled={loading}
          />
        </Form>
      </Modal.Body>
    </Modal>
  );
}
