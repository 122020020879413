import { Button, Modal, Spinner } from "react-bootstrap";
import "./index.scss";
import { useState } from "react";
import AdminService from "features/Admin/Api";
import { ToastPropsCustom } from "components/AppToastMessage";
import { AxiosError } from "axios";

type Props = {
  open: boolean;
  onClose: () => void;
  name: string;
  setShowToastContent: (message: Partial<ToastPropsCustom>) => void;
  refetchAreaList: () => void;
  onSuccess: () => void;
};

export default function ConfirmDeleteAreaModal({
  open,
  onClose,
  name,
  setShowToastContent,
  refetchAreaList,
  onSuccess,
}: Props) {
  const [loading, setLoading] = useState<boolean>(false);

  const handleDelete = async () => {
    setLoading(true);
    try {
      const response = await AdminService.deleteArea(name);
      if (response.data) {
        setShowToastContent({
          message: "エリアを削除しました。",
          type: "success",
          title: "成功",
        });
        refetchAreaList();
        onSuccess();
      }
    } catch (error) {
      console.log(error);
      setShowToastContent({
        message: ((error as AxiosError).response?.data as any).detail || "",
        type: "danger",
        title: "エラー",
      });
      onClose();
    } finally {
      setLoading(false);
    }
    setLoading(false);
  };

  return (
    <Modal
      show={open}
      keyboard={false}
      className="confirm-delete-area-modal"
      onHide={onClose}
      size="sm"
    >
      <Modal.Header className="confirm-delete-area-modal__header" closeButton>
        <Modal.Title className="text-light">エリアの削除を確認</Modal.Title>
      </Modal.Header>
      <Modal.Body className="confirm-delete-area-modal__body">
        <div>
          <p>{name} を削除してもよろしいですか？</p>
          <div className="action">
            <Button onClick={handleDelete} disabled={loading}>
              {loading && (
                <Spinner
                  animation="border"
                  role="status"
                  size="sm"
                  className="me-2"
                >
                  <span className="visually-hidden">Loading...</span>
                </Spinner>
              )}
              はい
            </Button>
            <Button variant="outline-danger" onClick={onClose}>
              キャンセル
            </Button>
          </div>
        </div>
      </Modal.Body>
    </Modal>
  );
}
