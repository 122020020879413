import "./index.scss";
import AppContent from "components/AppContent";
import { useEffect, useRef, useState } from "react";
import AppButton from "components/AppButton";
import DragDropFile from "components/AppDragDropFile";
import ToastMessage, { ToastPropsCustom } from "components/AppToastMessage";
import ExamService from "./Api";
import Form from "react-bootstrap/Form";
import { ProgressBar } from "react-bootstrap";
import { ACCEPT_ANSWER_SHEET, ACCEPT_ANSWER_SHEET_DROP_ZONE } from "configs";

const HomepageAnjo = () => {
  const [examName, setExamName] = useState<string>("");
  const [subjectName, setSubjectName] = useState<string>("");
  const [uploadPercentage, setUploadPercentage] = useState<number>(0);
  const [showToastContent, setShowToastContent] = useState<
    Partial<ToastPropsCustom>
  >({
    message: null,
  });
  const [examList, setExamList] = useState<{ name: string; value: number }[]>(
    []
  );
  const [subjectList, setSubjectList] = useState<
    { name: string; value: number }[]
  >([]);
  const [csvName, setCsvName] = useState<string>();
  const [answerSheetFile, setAnswerSheetFile] = useState<File[]>([]);
  const timeOut = useRef<any>();
  const [isLoading, setLoading] = useState<boolean>(false);

  useEffect(() => {
    (async () => {
      try {
        const response = await ExamService.getSubjectList();
        if (response.data.Subjects) {
          const { Subjects } = response.data;
          setSubjectList(Subjects);
          setSubjectName(Subjects[0].name);
          handleGetExamList();
        }
      } catch (error) {
        console.log(error);
      }
    })();
  }, []);

  useEffect(() => {
    return () => {
      if (timeOut.current) {
        clearTimeout(timeOut.current);
      }
    };
  }, []);

  useEffect(() => {
    if (answerSheetFile.length === 0) {
      setCsvName("");
    }
  }, [answerSheetFile]);

  const handleMarking = async () => {
    if (
      answerSheetFile.filter(
        (file: File) => !ACCEPT_ANSWER_SHEET.includes(file.type)
      ).length > 0
    ) {
      setShowToastContent({
        message: "解答用紙のファイルはjpgのみ受け付けます。",
        type: "danger",
        title: "エラー",
      });
      return;
    }

    const formData = new FormData();
    answerSheetFile.forEach((file: File) => {
      formData.append("answerSheets", file);
    });
    formData.append("exam", examName);
    formData.append("subject", subjectName);
    formData.append("url", "anjo");

    const options = {
      onUploadProgress: (progressEvent: any) => {
        const { loaded, total } = progressEvent;
        let percent = Math.floor((loaded * 100) / total);
        if (percent < 95) {
          setUploadPercentage(percent);
        } else {
          setUploadPercentage(95);
        }
      },
    };
    setLoading(true);
    setCsvName("");
    try {
      const response = await ExamService.newMarking(formData, options);
      if (response.data) {
        setUploadPercentage(100);
        clearTimeout(timeOut.current);
        timeOut.current = setTimeout(() => {
          setLoading(false);
          setUploadPercentage(0);
          setCsvName(response.data.name);
          setShowToastContent({
            message: "解答用紙ファイルのアップロードが成功しました。!",
            type: "success",
            title: "成功",
          });
        }, 1000);
      }
    } catch (error) {
      console.log(error);
      setUploadPercentage(0);
      setLoading(false);
    }
  };

  const handleDownloadScoreFile = async (csvName: string) => {
    try {
      const response = await ExamService.downloadCsvFile(csvName);
      if (response.data) {
        window.open(response.data.url, "_blank");
      }
    } catch (error) {
      console.log(error);
    }
  };

  const handleGetExamList = async () => {
    try {
      const response = await ExamService.getExamList();
      const { Exams } = response.data;
      if (Exams) {
        setExamList(Exams);
        setExamName(Exams[0].name);
      }
    } catch (error) {
      console.log(error);
    }
  };

  return (
    <AppContent>
      <div className="exam-container">
        <ToastMessage
          type={showToastContent.type}
          message={showToastContent.message || ""}
          isShow={!!showToastContent.message}
          onClose={() => setShowToastContent({ message: null })}
          title={showToastContent.title || ""}
        />
        <div className="exam-container__form">
          <div className="exam-container__form__title">教科</div>
          <div className="exam-container__form__item">
            <Form.Select
              aria-label="Default select example"
              onChange={(e) => setSubjectName(e.target.value)}
            >
              {subjectList &&
                subjectList.map((item, idx) => (
                  <option key={idx} value={item.value}>
                    {item.name}
                  </option>
                ))}
            </Form.Select>
          </div>
        </div>

        <div className="exam-container__form">
          <div className="exam-container__form__title">試験選択</div>
          <div className="exam-container__form__item">
            <Form.Select
              aria-label="Default select example"
              className="form-select"
              onChange={(e) => setExamName(e.target.value)}
              value={examName}
            >
              {examList &&
                examList.map((item, idx) => (
                  <option key={idx} value={item.value}>
                    {item.name}
                  </option>
                ))}
            </Form.Select>
            {/* <div className="exam-container__form__item__note">
              ※ 県版４回でご採用の場合、
              <br />
              １回目の実施は３回目を選択してください。
            </div> */}
          </div>
        </div>

        <div className="exam-container__form">
          <div className="exam-container__form__title">
            解答用紙アップロード
          </div>
          <div className="exam-container__form__item">
            <DragDropFile
              textGuide={
                <>
                  こちらにファイルをドラッグアンドドロップするか、
                  <br />
                  ファイルを選択するためにクリックしてください。
                </>
              }
              isMultiple={true}
              setFileValues={(file) => setAnswerSheetFile(file)}
              uploadPercent={uploadPercentage}
              acceptFile={ACCEPT_ANSWER_SHEET_DROP_ZONE}
            />
          </div>
        </div>

        <div className="exam-container__form">
          <AppButton
            text="採点"
            btntype={"primary"}
            type="button"
            disabled={!answerSheetFile.length || isLoading} //!examName ||
            onClick={() => handleMarking()}
          />
        </div>

        {uploadPercentage !== 0 && (
          <div className="exam-container__form">
            <ProgressBar
              animated
              variant="success"
              now={uploadPercentage}
              label={`${uploadPercentage}%`}
            />
          </div>
        )}

        {csvName && answerSheetFile.length > 0 && (
          <div className="exam-container__form">
            <b>結果: </b>
            <span
              className="file-score"
              onClick={() => handleDownloadScoreFile(csvName)}
            >
              {csvName}
            </span>
          </div>
        )}
      </div>
    </AppContent>
  );
};

export default HomepageAnjo;
