import axios, {
  AxiosError,
  AxiosInstance,
  InternalAxiosRequestConfig,
  AxiosResponse,
} from "axios";
import AdminStorage from "features/Admin/Storage";
import { setErrorState } from "stores/useGlobalState";

const instance: AxiosInstance = axios.create({
  baseURL: `/api`,
  headers: {
    "Content-Type": "application/json",
  },
});

instance.interceptors.request.use(
  (options: InternalAxiosRequestConfig) => {
    const token = AdminStorage.getToken();
    options.headers.setContentType(
      options.headers["Content-Type"]
        ? options.headers["Content-Type"]
        : "application/json"
    );
    options.headers.Authorization = token ? `Bearer ${token}` : "";
    return options;
  },
  (error: AxiosError) => Promise.reject(error)
);

instance.interceptors.response.use(
  (response: AxiosResponse) => {
    return response;
  },
  (error: AxiosError) => {
    if (error.response?.request.status === 400) {
      const errorMessage = (error.response.data as any).detail;
      setErrorState(errorMessage);
    }
    return Promise.reject(error);
  }
);

export default instance;
