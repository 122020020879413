import AppHttpClient from "../../../core/http/index";

const getAreaList = () => AppHttpClient.post("/get_list_area", {});

const getSubjectList = (body: { url: string }) =>
  AppHttpClient.post("/get_list_subject", body);

const getExamList = (body: { url: string }) =>
  AppHttpClient.post("/get_list_exam", body);

const uploadAnswer = (body: FormData, options: any) =>
  AppHttpClient.postFormData("/upload_answer", body, options);

const login = (body: { username: string; password: string }) =>
  AppHttpClient.post("/login", body);

const createArea = (body: {
  area_name: string;
  url: string;
  subject_list: string[];
  exam_num: number;
}) => AppHttpClient.post("/create_area", body);

const deleteArea = (areaName: string) =>
  AppHttpClient.delete(`/delete_area?area_name=${areaName}`);

const AdminService = {
  getAreaList,
  getExamList,
  getSubjectList,
  uploadAnswer,
  login,
  createArea,
  deleteArea,
};
export default AdminService;
