import { Button, Form, Modal } from "react-bootstrap";
import AppButton from "components/AppButton";
import "./index.scss";
import { FormEvent, useState } from "react";
import AdminService from "features/Admin/Api";
import { ToastPropsCustom } from "components/AppToastMessage";

const REGEX_SELECTION = /^[0-9]+$/;
const REGEX_URL = /^[a-z0-9-_]+$/;

type Props = {
  openAddPrefecture: boolean;
  setOpenAddPrefecture: (open: boolean) => void;
  setShowToastContent: (message: Partial<ToastPropsCustom>) => void;
  refetchAreaList: () => void;
};

export default function AddPrefecture({
  openAddPrefecture,
  setOpenAddPrefecture,
  setShowToastContent,
  refetchAreaList,
}: Props) {
  const [prefecture, setPrefecture] = useState("");
  const [url, setUrl] = useState("");
  const [subjects, setSubjects] = useState<string[]>([""]);
  const [selection, setSelection] = useState<string>("1");

  const [validated, setValidated] = useState(false);

  const [loading, setLoading] = useState<boolean>(false);

  const [serverError, setServerError] = useState<string>("");

  const resetForm = () => {
    setPrefecture("");
    setUrl("");
    setSubjects([""]);
    setSelection("1");
    setValidated(false);
    setServerError("");
  };

  const handleClose = () => {
    resetForm();
    setOpenAddPrefecture(false);
  };

  const handleRoundSelection = () => {
    let newSelection = Math.round(Number(selection));
    if (newSelection < 1) newSelection = 1;
    if (newSelection > 50) newSelection = 50;
    setSelection(newSelection.toString());
    return newSelection;
  };

  const handleSubmit = async (e: FormEvent) => {
    e.preventDefault();
    setValidated(true);

    const newSelection = handleRoundSelection();

    if (
      !REGEX_SELECTION.test(newSelection.toString()) ||
      newSelection < 1 ||
      !REGEX_URL.test(url)
    )
      return;

    const data = {
      area_name: prefecture,
      url,
      subject_list: subjects,
      exam_num: newSelection,
    };

    try {
      setLoading(true);
      const res = await AdminService.createArea(data);
      setShowToastContent({
        message: "県の追加に成功しました。",
        type: "success",
        title: "成功",
      });
      refetchAreaList();
      handleClose();
      setLoading(false);
    } catch (error: any) {
      setServerError(error.response.data.detail);
      setLoading(false);
    }
  };

  const isDisabledSubmit =
    !prefecture ||
    !url ||
    subjects.some((subject) => !subject) ||
    !selection ||
    loading;

  const invalidUrl = validated && !REGEX_URL.test(url);
  const invalidSelection =
    validated && (!REGEX_SELECTION.test(selection) || Number(selection) < 1);

  return (
    <Modal
      show={openAddPrefecture}
      backdrop="static"
      keyboard={false}
      className="add-prefecture-modal"
      onHide={handleClose}
      size="lg"
    >
      <Modal.Header className="add-prefecture-modal__header" closeButton>
        <Modal.Title className="text-light">県の追加</Modal.Title>
      </Modal.Header>
      <Modal.Body className="add-prefecture-modal__body">
        <Form onSubmit={handleSubmit}>
          {serverError && (
            <Form.Text className="text-danger d-inline-block mb-2">
              {serverError}
            </Form.Text>
          )}
          <Form.Group className="mb-3">
            <Form.Label>県</Form.Label>
            <Form.Control
              type="text"
              placeholder="県を入力してください。"
              value={prefecture}
              onChange={(e) => setPrefecture(e.target.value)}
            />
          </Form.Group>
          <Form.Group className="mb-3">
            <Form.Label>URL</Form.Label>
            <div className="add-prefecture-modal__body--url">
              <span>{window.location.origin}/</span>
              <Form.Control
                type="text"
                placeholder="URLを入力してください。"
                value={url}
                onChange={(e) => setUrl(e.target.value)}
                className={invalidUrl ? "border border-danger" : undefined}
              />
            </div>
            {invalidUrl && (
              <Form.Text className="text-danger">無効なURL</Form.Text>
            )}
          </Form.Group>
          <Form.Group className="mb-3">
            <Form.Label>教科</Form.Label>
            {subjects.map((subject, index) => (
              <div
                className="add-prefecture-modal__body--subject mb-2"
                key={index}
              >
                <Form.Control
                  type="text"
                  placeholder="教科を入力してください。"
                  value={subject}
                  onChange={(e) =>
                    setSubjects((prev) => {
                      prev[index] = e.target.value;
                      return [...prev];
                    })
                  }
                />
                {index === 0 ? (
                  <Button
                    type="button"
                    onClick={() => setSubjects([...subjects, ""])}
                    className="btn-add"
                  >
                    +
                  </Button>
                ) : (
                  <Button
                    type="button"
                    className="btn-danger"
                    onClick={() =>
                      setSubjects((prev) => {
                        const newValue = prev.filter((item, i) => i !== index);
                        return [...newValue];
                      })
                    }
                  >
                    -
                  </Button>
                )}
              </div>
            ))}
          </Form.Group>
          <Form.Group className="mb-3">
            <Form.Label>試験選択</Form.Label>
            <Form.Control
              type="number"
              value={selection}
              onChange={(e) => setSelection(e.target.value)}
              placeholder="試験選択を入力してください。"
              className={invalidSelection ? "border border-danger" : undefined}
              onBlur={handleRoundSelection}
            />
            {invalidSelection && (
              <Form.Text className="text-danger">無効な選択</Form.Text>
            )}
          </Form.Group>
           
          <AppButton
            text="県の追加"
            btntype={"primary"}
            type="submit"
            disabled={isDisabledSubmit}
          />
        </Form>
      </Modal.Body>
    </Modal>
  );
}
